import React from "react"
import styled from "styled-components"
import workflowManagementImg from "../images/workflow-management.png"

const FeaturesSection = () => (
  <SiteFeaturesSection>
    <div className="features-wrapper">
      <img
        className="tabletImg"
        src={workflowManagementImg}
        alt="workflow management"
      />
      <div className="main">
        <h3>ONE STOP WORKFLOW MANAGEMENT</h3>
        <p>
          Your customer accepts your quote – now what? Let MyServicePak empower
          you to assign a work ticket, track it, and close it. This tool fits
          perfectly between your quoting and billing processes. It is the only
          tool your techs need to confirm they did the job right.
        </p>
        <div className="main-bullets">
          <p>• Real time visibility to all jobs from anywhere</p>
          <p>• Confirmation of job completion every time</p>
          <p>• Complete customer history at a touch</p>
        </div>
      </div>
      <div className="list">
        <h3>FEATURES</h3>
        <p>• Easily manage employees or contractors</p>
        <p>• Create custom service checklists to drive consistency</p>
        <p>• Track on time performance</p>
        <p>• Before and after pictures for every job</p>
        <p>• Notifications of questions, issues or job completion</p>
        <p>• Seamless reassignment of jobs</p>
        <p>• Easily upload customer lists</p>
        <p>• Customizable user access</p>
        <p>• All your service tickets – organized</p>
      </div>
    </div>
  </SiteFeaturesSection>
)

export default FeaturesSection

const SiteFeaturesSection = styled.div`
  .features-wrapper {
    color: ${props => props.theme.color.blue__400};
    display: flex;
    margin: auto;
    max-width: ${props => props.theme.section.width};
    padding: ${props => props.theme.section.padding};

    .tabletImg {
      height: 300px;
    }

    .main,
    .list {
      padding: 0 20px 20px 30px;

      h3 {
        margin-bottom: 24px;
      }
    }

    .main {
      width: 634px;

      .main-bullets {
        margin-top: 24px;
      }
    }

    .list {
      border-left: 1px solid ${props => props.theme.color.black};
      padding-right: 0;
    }

    @media (max-width: ${props => props.theme.breakpoint.sectionWidth}) {
      .list {
        padding-right: 10px;
      }
    }

    @media (max-width: ${props => props.theme.breakpoint.widescreen}) {
      flex-direction: column;

      .main,
      .list {
        margin: 0 auto;
        width: 656px;
      }

      .tabletImg {
        display: none;
      }

      .list {
        border: none;
        margin-top: 30px;
      }
    }

    @media (max-width: ${props => props.theme.breakpoint.desktop}) {
      padding-top: 0;

      .main,
      .list {
        width: 100%;
        padding: 0 30px 20px;

        p {
          margin: 2px 0;
        }
      }
    }

    @media (max-width: ${props => props.theme.breakpoint.mobile}) {
      .main,
      .list {
        padding: 0 30px 0;
      }
    }
  }
`
