import React from "react"
import styled from "styled-components"

const SignUpSection = () => (
  <SiteSignUpSection>
    <div className="signup-wrapper">
      <h2>YOU CAN ADD TECHNOLOGY AND KEEP IT SIMPLE</h2>

      <p>You know you need technology to compete</p>
      <p>
        Most software is too pricey, too complicated and you only need 20% of it
      </p>
      <p>
        Long-term software contracts are scary for something that doesn’t even fit your business
      </p>
      <p className="copy">
        We built this because you asked. We give you what you need, at a price
        you won’t believe. That’s why we said forget the contracts. If it helps
        your business, you will stay. Try it now... risk free. And get organized
        and save time today.
      </p>
      <button className="btn">
        <a href="https://msp.myservicepak.com/sign_up">
          <h6>SIGN UP</h6>
        </a>
      </button>
    </div>
  </SiteSignUpSection>
)

export default SignUpSection

const SiteSignUpSection = styled.div`
  background: ${props => props.theme.color.lightgrey};
  
  /* remove when covid section is added */
  border-bottom: 1px solid grey;

  .signup-wrapper {
    margin: auto;
    max-width: ${props => props.theme.section.width};
    padding: 60px 65px 50px;
    text-align: center;

    h2 {
      margin-bottom: 30px;
    }

    p {
      margin: 10px auto;
    }

    .copy {
      margin-top: 32px;
    }

    .btn {
      background: ${props => props.theme.color.red__400};
      border: none;
      box-shadow: -4px 4px ${props => props.theme.color.shadowgrey};
      cursor: pointer;
      margin-top: 15px;
      padding: 8px 28px;

      a {
        text-decoration: none;
      }

      h6 {
        font-size: ${props => props.theme.font.size.small};
      }
    }

    @media (max-width: ${props => props.theme.breakpoint.desktop}) {
      padding: 30px;

      h2 {
        font-size: ${props => props.theme.font.size.large};
      }

      p {
        font-size: ${props => props.theme.font.size.small};
      }

      .btn {
        height: 38px;
        width: 142px;

        h6 {
          font-size: ${props => props.theme.button.fontSizeLarge};
        }
      }
    }
  }
`
