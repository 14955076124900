import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import Icon from "../components/icon"

import timeIcon from "../images/icons/time.svg"
import assignAnyJobIcon from "../images/icons/assignanyjob.svg"
import openJobsCompleteIcon from "../images/icons/openjobscomplete.svg"
import panelOneBg from "../images/hero-4.png"
import appPanelBg from "../images/app-panel-background.png"

// all comments are original code for panel__two background image/gradient
// left in case background needs adjusting
// (screenshot used as bg instead to keep img centered)
// import appScreenTabletImg from "../images/app-screen-tablet.png"

const PanelSection = () => (
  <SitePanelSection>
    <div className="panels-wrapper">
      <div
        className="panel panel__one"
        style={{ backgroundImage: `url(${panelOneBg})` }}
      >
        <div className="panel__one-overlay" />
        <h3>ASSIGN IT. TRACK IT. STORE IT.</h3>
        <div className="panel__one-icons-wrapper">
          <Icon
            white
            src={assignAnyJobIcon}
            copy="Assign any job to an employee or contractor"
          />
          <Icon
            white
            src={timeIcon}
            copy="Set time schedules and expectations"
          />
          <Icon
            white
            src={openJobsCompleteIcon}
            copy="See open jobs and get notified when a job is complete"
          />
        </div>
      </div>
      <div className="small__panels">
        <div
          className="panel panel__two"
          style={{ backgroundImage: `url(${appPanelBg})` }}
        >
          {/* <img className="panel__two-img" src={appScreenTabletImg} /> */}
          <h3>THE TOOL</h3>
          <button className="panel__two-btn">
            <Link to="/tool/">
              <h6>LEARN MORE</h6>
              </Link>
          </button>
        </div>
        <div className="panel panel__three">
          <h3>GET STARTED</h3>
          <button className="panel__three-btn">
            <Link to="/get-started/">
              <h6>LEARN MORE</h6>
            </Link>
          </button>
        </div>
      </div>
    </div>
  </SitePanelSection>
)

export default PanelSection

const SitePanelSection = styled.div`
  .panels-wrapper {
    display: flex;
    margin: auto;
    max-width: ${props => props.theme.section.width};
    padding: ${props => props.theme.section.padding};

    h3,
    p {
      color: ${props => props.theme.color.white};
    }

    .panel {
      align-items: center;
      height: ${props => props.theme.panel.height};
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: ${props => props.theme.panel.width};
    }

    .panel__one {
      background-position: center top;
      background-size: 220%;
      width: 656px;

      h3 {
        margin: 30px 0 14px;
        z-index: 5;
      }

      .panel__one-icons-wrapper {
        display: flex;
        z-index: 5;
      }

      .panel__one-overlay {
        background: black;
        height: ${props => props.theme.panel.height};
        opacity: 0.7;
        position: absolute;
        width: 656px;
      }
    }

    .small__panels {
      display: flex;

      .panel__two {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin: 0 4px;

        /* background: linear-gradient(
          0deg,
          rgba(21, 81, 116, 1) 29%,
          rgba(0, 0, 0, 1) 100%
        );
        overflow: hidden;
        position: relative;

        .panel__two-img {
          left: 45px;
          position: absolute;
          top: 195px;
          width: 227px;
        } */
      }

      .panel__three {
        background: ${props => props.theme.color.red__400};
      }

      .panel__two-btn,
      .panel__three-btn {
        border: none;
        border-radius: 4px;
        cursor: pointer;
        height: 48px;
        margin-top: 30px;
        width: 170px;

        a {
          text-decoration: none;
        }

        h6 {
          font-size: ${props => props.theme.button.fontSizeXLarge};
        }
      }

      .panel__two-btn {
        background: ${props => props.theme.color.white};
        z-index: 5;

        h6 {
          color: ${props => props.theme.color.black};
        }
      }

      .panel__three-btn {
        background: ${props => props.theme.color.blue__400};
        color: ${props => props.theme.color.white};
      }
    }

    @media (max-width: ${props => props.theme.breakpoint.sectionWidth}) {
      flex-direction: column;
      align-items: center;

      .small__panels {
        padding-top: 10px;

        .panel__two {
          margin: 0 5px 0 0;
        }
        .panel__three {
          margin: 0 0 0 5px;
        }
      }
    }

    @media (max-width: ${props => props.theme.breakpoint.desktop}) {
      padding-top: 0;

      .panel {
        height: ${props => props.theme.panel.heightTablet};
      }

      .panel__one {
        width: 100%;

        h3 {
          margin: 0 0 14px;
        }

        .panel__one-overlay {
          height: ${props => props.theme.panel.heightTablet};
          width: 100%;
        }
      }

      .small__panels {
        padding: 10px;
        width: 100%;

        .panel__two,
        .panel__three {
          margin: 4px;
          width: 100%;
        }
      }
    }

    @media (max-width: ${props => props.theme.breakpoint.largePhone}) {
      .panel {
        height: ${props => props.theme.panel.heightMobile};

        h3 {
          font-size: 1.4rem;
        }
      }

      .panel__one > .panel__one-overlay {
        height: ${props => props.theme.panel.heightMobile};
      }

      .small__panels {
        .panel__two-btn,
        .panel__three-btn {
          height: 40px;
          margin-top: 20px;
          width: 120px;

          h6 {
            font-size: ${props => props.theme.font.size.small};
          }
        }
      }
    }

    @media (max-width: ${props => props.theme.breakpoint.largePhone}) {
      .panel__two,
      .panel__three {
        height: 180px;
      }
    }
  }
`
