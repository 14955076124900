import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

const StepSection = () => (
  <SiteStepSection>
    <div className="step-wrapper">
      <div className="vid-wrapper">
        <iframe
          src="https://player.vimeo.com/video/411427422"
          title="overview"
          className="vid"
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
        />
        <h6>MyServicePak Overview</h6>
      </div>
      <div className="steps">
        <h3>Inside the Tool: 5 Steps to success</h3>
        <h6>1. Set Up Your Users</h6>
        <h6>2. Build your site</h6>
        <h6>3. Put each job on a schedule</h6>
        <h6>4. Assign tickets, attach checklists</h6>
        <h6>5. Focus on the customers you don't have</h6>
        <button className="btn">
          <Link to="/tool/">
            <h6>LEARN MORE</h6>
          </Link>
        </button>
      </div>
    </div>
  </SiteStepSection>
)

export default StepSection

const SiteStepSection = styled.div`
  background: ${props => props.theme.color.lightgrey};
  margin-bottom: 50px;

  .step-wrapper {
    display: flex;
    margin: auto;
    max-width: ${props => props.theme.section.width};
    padding: 55px 15px 30px 35px;

    .vid-wrapper {
      margin-right: 30px;
      text-align: center;
      width: 700px;

      .vid {
        width: 640px;
        height: 360px;
      }

      h6 {
        color: ${props => props.theme.color.black};
        font-size: ${props => props.theme.font.size.medium};
      }
    }

    .steps {
      margin: 20px 0 0 30px;

      h3 {
        margin-bottom: 35px;
      }

      h6 {
        color: ${props => props.theme.color.black};
        font-size: ${props => props.theme.font.size.medium};
        line-height: 1.5;
      }

      .btn {
        background: ${props => props.theme.color.red__400};
        border: none;
        box-shadow: -4px 4px ${props => props.theme.color.shadowgrey};
        cursor: pointer;
        margin: 20px 0 30px;
        padding: 8px 28px;

        a {
          text-decoration: none;
        }

        h6 {
          color: ${props => props.theme.color.white};
          font-size: ${props => props.theme.font.size.small};
        }
      }
    }

    @media (max-width: ${props => props.theme.breakpoint.widescreen}) {
      align-items: center;
      flex-direction: column;
      padding: 40px 30px;

      .vid-wrapper,
      .steps {
        margin: 0;
        width: 640px;
      }

      .vid-wrapper {
        h6 {
          margin-bottom: 30px;
        }
      }

      .steps {
        .btn {
          margin-bottom: 0;

          h6 {
            font-size: ${props => props.theme.button.fontSizeXLarge};
          }
        }
      }
    }

    @media (max-width: ${props => props.theme.breakpoint.desktop}) {
      .vid-wrapper,
      .steps {
        width: 480px;
      }

      .vid-wrapper > .vid {
        width: 480px;
        height: 270px;
      }
    }
    @media (max-width: ${props => props.theme.breakpoint.largePhone}) {
      .vid-wrapper,
      .steps {
        width: 100%;
      }

      .vid-wrapper > .vid {
        width: 320px;
        height: 180px;
      }
    }

    @media (max-width: ${props => props.theme.breakpoint.mobile}) {
      .vid-wrapper,
      .steps {
        width: 100%;
      }

      .vid-wrapper > .vid {
        width: 240px;
        height: 135px;
      }
    }
  }
`
