import React from "react"
import styled from "styled-components"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import heroBgOne from "../images/slide-1.jpg"
import heroBgTwo from "../images/slide-2.jpg"
import heroBgThree from "../images/slide-3.jpg"
import heroBgFour from "../images/slide-4.jpg"

const slides = [
  {
    img: heroBgOne,
    align: "center",
    title: "Service Work Made Simple.",
    text:
      "A small business workflow management system that fits your company and your budget. Try it NOW!",
  },
  {
    img: heroBgTwo,
    align: "center",
    title: "Every Technician. Every Job. At my Fingertips. All the Time.",
    text:
      "MyServicePak is the work ticket solution you’ve been waiting for. Created for small business, by small business. Try it NOW!",
  },
  {
    img: heroBgThree,
    align: "bottom",
    title: "Easy, Inexpensive, No Commitment. Work smarter today.",
    text:
      "The workflow management system to assign and manage jobs right from your phone. Everything you need. Nothing more. Nothing Less.",
  },
  {
    img: heroBgFour,
    align: "center",
    title: "Only worry about the customers you don’t have.",
    text:
      "Break free of long-term contracts for complicated, overpriced software and let MyServicePak make your life easier.",
  },
]

const sliderSettings = {
  accessibility: true,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 8000,
  dots: true,
  dotsClass: "dots",
  infinite: true,
  pauseOnHover: false,
}

const HeroSlider = () => (
  <SiteHeroSlider>
    <Slider {...sliderSettings}>
      {slides.map(({ img, align, title, text }) => (
        <div key={title}>
          <div
            className="hero"
            style={{
              backgroundImage: `url(${img})`,
              backgroundPosition: align,
            }}
          />
          <div className="copy">
            <h2>{title.toUpperCase()}</h2>
            <p>{text}</p>
          </div>
        </div>
      ))}
    </Slider>
  </SiteHeroSlider>
)

export default HeroSlider

const SiteHeroSlider = styled.div`
  .dots {
    position: absolute;
    bottom: 45px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;

    li {
      position: relative;
      display: inline-block;
      height: 14px;
      width: 14px;
      margin: 0 8px;
      padding: 0;
      cursor: pointer;
      button {
        border: 0;
        border-radius: 50%;
        background: ${props => props.theme.color.white};
        display: block;
        height: 14px;
        width: 14px;
        outline: none;
        line-height: 0px;
        font-size: 0px;
        color: ${props => props.theme.color.white};
        padding: 5px;
        cursor: pointer;
        &:hover,
        &:focus {
          outline: none;
          &:before {
            opacity: 1;
          }
        }
        &:before {
          position: absolute;
          top: 0;
          left: 0;
          content: "•";
          width: 14px;
          height: 14px;
          font-family: "slick";
          font-size: 2px;
          line-height: 14px;
          text-align: center;
          color: white;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
      }
      &.slick-active button:before {
        color: ${props => props.theme.color.red__400};
        background: ${props => props.theme.color.red__400};
        border: 1px solid white;
        border-radius: 50%;
      }
    }

    @media (max-width: ${props => props.theme.breakpoint.desktop}) {
      bottom: 1000px;
    }
  }

  .hero {
    background-repeat: no-repeat;
    background-size: cover;
    height: 570px;

    @media (max-width: ${props => props.theme.breakpoint.desktop}) {
      height: 300px;
    }

    @media (max-width: ${props => props.theme.breakpoint.largePhone}) {
      height: 200px;
    }
  }

  .copy {
    background: ${props => props.theme.color.blue__400};
    opacity: 0.8;
    padding: 45px 30px 45px 75px;
    position: absolute;
    text-align: left;
    top: 155px;
    width: 760px;
    z-index: 5;

    h2 {
      margin-bottom: 20px;
    }

    h2,
    p {
      color: ${props => props.theme.color.white};
    }

    @media (max-width: ${props => props.theme.breakpoint.desktop}) {
      display: flex;
      flex-direction: column;
      height: 215px;
      justify-content: space-evenly;
      opacity: 1;
      padding: 20px 30px;
      position: relative;
      top: 0;
      width: 100%;

      h2 {
        font-size: ${props => props.theme.font.size.large};
      }
    }

    @media (max-width: ${props => props.theme.breakpoint.largePhone}) {
      height: 165px;

      h2 {
        font-size: ${props => props.theme.font.size.medium};
      }

      p {
        font-size: ${props => props.theme.font.size.small};
        line-height: 1.2;
      }
    }
  }
`
