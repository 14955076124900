import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import HeroSlider from "../components/heroSlider"
import StepSection from "../components/stepSection"
import IconSection from "../components/iconSection"
import PanelSection from "../components/panelSection"
// import CovidSection from "../components/covidSection"
import SignUpSection from "../components/signUpSection"
import FeaturesSection from "../components/featuresSection"
import TestimonialSection from "../components/testimonialSection"
import VideoEmbed from "../components/videoEmbed"

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <HeroSlider />
      <VideoEmbed text="" paddingDesktop="50px 82px 0px" paddingMobile="30px 30px">
        <h3 style={{marginBottom: '40px'}}>Check out what actual users say about MyServicePak.</h3>
        <iframe title="MyServicePak Demo Video" src="https://player.vimeo.com/video/534032842" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
      </VideoEmbed>
      <PanelSection />
      <FeaturesSection />
      <SignUpSection />
      {/* <CovidSection /> temporarily removed */}
      <IconSection />
      <TestimonialSection />
      <StepSection />
    </Layout>
  )
}

export default IndexPage
